import React, { Fragment, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { PbPageData } from "mibaby-app-page-builder/types";
import Layout from "./Layout";
import Element from "@webiny/app-page-builder/render/components/Element";
import useResponsiveClassName from "@webiny/app-page-builder/hooks/useResponsiveClassName";
import DefaultNotFoundPage from "theme/pageBuilder/components/defaultPages/DefaultNotFoundPage";
import DefaultErrorPage from "theme/pageBuilder/components/defaultPages/DefaultErrorPage";
import { useLocation } from "@webiny/react-router";
import { plugins } from "@webiny/plugins";
import { PbAddonPageRenderPlugin } from "mibaby-app-page-builder/types";
import trimEnd from "lodash/trimEnd";
// import { useI18nContext } from "mibaby-app/contexts/I18n";

declare global {
    // eslint-disable-next-line
    namespace JSX {
        interface IntrinsicElements {
            "ps-tag": {
                key?: string;
                value?: string;
            };
        }
    }
}

/**
 * This component will render the page, including the page content, its layout, and also meta tags.
 * @param data
 * @param error
 * @constructor
 */
function Render({
    loading,
    page,
    error,
    settings,
}: {
    loading: boolean;
    page: PbPageData;
    error: Record<string, any>;
    settings: Record<string, any>;
}) {
    const { pageElementRef, responsiveClassName } = useResponsiveClassName();
    // const i18n = useI18nContext();
    // const locale = i18n.getLocale();

    // Scroll to anchor
    useScrollToAnchor([loading, page]);

    if (error) {
        switch (error.code) {
            case "NOT_FOUND":
                if (error.data) {
                    page = error.data;
                    break;
                }
                return <DefaultNotFoundPage />;
            default:
                return <DefaultErrorPage error={error} />;
        }
    }

    if (!page) {
        return null;
    }

    return (
        <div className="webiny-pb-page">
            <ps-tag data-key={"pb-page"} data-value={page.id} />
            <PageHead page={page} settings={settings} />
            <PageAddons page={page} />
            <div className={responsiveClassName} ref={pageElementRef}>
                <Layout page={page} settings={settings}>
                    <Element element={page.content} />
                </Layout>
            </div>
        </div>
    );
}

const PageHead = ({ page, settings }: { page: PbPageData; settings: Record<string, any> }) => {
    const head: Record<string, any> = {
        favicon: settings?.favicon,
        title: page.settings?.general.titleExternal || page.title,
        seo: {
            title: "",
            subtitle: "",
            description: "",
            canonical: "",
            robots: {},
            ...page?.settings?.seo,
        },
        social: {
            title: "",
            description: "",
            image: null,
            ...page?.settings?.social,
        },
    };
    let siteLanguage = "de";

    const tld = typeof window !== "undefined" ? window.location?.hostname?.split(".").pop() : "";

    if (tld === "fr") {
        siteLanguage = "fr";
    } else if (tld === "it") {
        siteLanguage = "it";
    } else if (tld === "eu") {
        siteLanguage = "es";
    } else if (tld === "nl") {
        siteLanguage = "nl";
    } else if (tld === "be") {
        siteLanguage = "nl";
    } else if (tld === "io") {
        siteLanguage = "en";
    } else if (tld === "us") {
        siteLanguage = "en";
    } else if (tld === "pt") {
        siteLanguage = "pt";
    } else if (tld === "club") {
        siteLanguage = "pt";
    } else if (tld === "pl") {
        siteLanguage = "pl";
    } else if (tld === "tr") {
        siteLanguage = "tr";
    } else if (tld === "cz") {
        siteLanguage = "cz";
    }

    if (settings.websiteUrl && head.seo.canonical?.startsWith("/")) {
        head.seo.canonical = trimEnd(settings.websiteUrl, "/") + head.seo.canonical;
    }

    const robots = Object.entries(head.seo.robots || {})
        .filter(([key]) => !key.startsWith("_"))
        .map(([key, val]) => (!val ? "no" : "") + key)
        .join(", ");

    return (
        <Helmet htmlAttributes={{
            lang: siteLanguage,
        }}>
            {/* Read favicon from settings. */}
            {head.favicon && (
                <link
                    rel="icon"
                    type="image/png"
                    href={head.favicon.src + "?width=128"}
                    sizes="16x16"
                />
            )}

            {/* Read title from page settings. */}
            {head.title && <title>{head.title}</title>}

            {/* Read SEO tags from page settings. */}
            {head.seo.title && <meta name="title" content={head.seo.title} />}
            {head.seo.description && <meta name="description" content={head.seo.description} />}
            {head.seo.canonical && <link rel="canonical" href={head.seo.canonical} />}
            {robots && <meta name="robots" content={robots} />}
            {head.seo.meta?.map(({ name, content }, index) => (
                <meta key={index} name={name} content={content} />
            ))}

            {/* Read social tags from page settings. */}
            {settings?.social?.title && (
                <meta property="og:title" content={settings.social.title} />
            )}

            {head.social.image && (
                <meta property="og:image" content={head.social.image.src + "?width=1596"} />
            )}
            {head.social.title && <meta property="og:title" content={head.social.title} />}

            {head.social.description && (
                <meta property="og:description" content={head.social.description} />
            )}
            {head.social.meta?.map(({ property, content }, index) => {
                // Replace duplicate "og:og:" with single "og:".
                const preparedProperty = `og:${property}`.replace("og:og:", "og:");
                return <meta key={index} property={preparedProperty} content={content} />;
            })}
        </Helmet>
    );
};

const PageAddons = ({ page }: { page: PbPageData }) => {
    const pageRenderPlugins = useMemo(
        () => plugins.byType<PbAddonPageRenderPlugin>("addon-page-render"),
        []
    );

    return (
        <>
            {pageRenderPlugins.map(pl => {
                try {
                    return <Fragment key={pl.name}>{pl.render({ page })}</Fragment>;
                } catch (e) {
                    console.error(e);
                    return null;
                }
            })}
        </>
    );
};

const useScrollToAnchor = (deps = []) => {
    const { hash } = useLocation();
    useEffect(() => {
        let el;
        try {
            el = hash && document.querySelector(hash);
        } catch (e) {
            // Invalid CSS-Selector? Nobody cares!
        }
        if (el) {
            el.scrollIntoView();
            const timeout = setTimeout(() => el.scrollIntoView({ behavior: "smooth" }), 1000);
            return () => clearTimeout(timeout);
        }
    }, [hash, ...deps]);
};

export default Render;
