import React, { useContext, useMemo } from "react";
import type { PbAddonRenderPlugin } from "@webiny/app-page-builder/types";
import { plugins } from "@webiny/plugins";
import type { PbRouteContext, PbRouteLayoutPlugin } from "mibaby-app-page-builder/types";
import { CommentProvider } from "mibaby-app/components/social/comments";
import Context from "mibaby-frontend/route/Context";

export type BaseProps = {
    children: React.ReactNode;
};

export const Base = ({ children }: BaseProps) => {
    const route = useContext(Context) as PbRouteContext;
    const addons = useMemo(() => plugins.byType<PbAddonRenderPlugin>("addon-render"), []);

    const routeLayoutPlugin = useMemo(
        () => plugins.byType<PbRouteLayoutPlugin>("route-layout").find(pl => pl.match(route)),
        [route]
    );

    return (
        <>
            {addons.map(pl => React.cloneElement(pl.component, { key: pl.name }))}
            <CommentProvider>
                {routeLayoutPlugin ? routeLayoutPlugin.render({ route, children }) : children}
            </CommentProvider>
        </>
    );
};

export default Base;
