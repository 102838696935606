import React from "react";
import { FbFormModelField } from "@webiny/app-form-builder/types";
import { BindComponentRenderProp } from "@webiny/form";
import { Title, HelperMessage, className, PREFIX } from "./common";
import RichTextValue from "mibaby-app/components/site/RichTextValue";

type CheckboxProps = {
    bind: BindComponentRenderProp;
    field: FbFormModelField;
};

const Checkbox = ({ field, bind }: CheckboxProps) => {
    const { onChange, value, validation, validate } = bind;
    const { fieldId, helpText, settings } = field;

    const handleChange = (val: boolean) => {
        onChange(val);
        validate && validate();
    };

    return (
        <div className={className("checkbox", fieldId)}>
            <Title field={field} />
            <div className={`${PREFIX}__checkbox-group`}>
                <div className={`${PREFIX}__checkbox`} key={value}>
                    <input
                        name={fieldId}
                        className={`${PREFIX}__checkbox-input`}
                        type="checkbox"
                        id={"checkbox-" + fieldId}
                        checked={Boolean(value)}
                        onChange={e => handleChange(e.target.checked)}
                    />
                    <label htmlFor={"checkbox-" + fieldId} className={`${PREFIX}__checkbox-label`}>
                        <RichTextValue value={settings.text} />
                    </label>
                </div>
            </div>
            <HelperMessage
                isValid={validation.isValid}
                errorMessage={validation.message}
                helperMessage={<RichTextValue value={helpText} />}
            />
        </div>
    );
};

export default Checkbox;
