import { plugins } from "@webiny/plugins";
// import imageComponent from "@webiny/app/plugins/image";
import mbPlugins from "mibaby-app/plugins";
import mbSitePlugins from "mibaby-app/plugins/site";
import pageBuilder from "./pageBuilder";
import formBuilder from "./formBuilder";
import apolloLinks from "./apolloLinks";

import theme from "theme";

plugins.register([mbPlugins(), mbSitePlugins, pageBuilder, formBuilder, apolloLinks(), theme()]);
