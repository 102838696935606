import * as React from "react";
import { FbFormModelField } from "@webiny/app-form-builder/types";
import { BindComponentRenderProp } from "@webiny/form";
import { Title, HelperMessage, className, PREFIX } from "./common";
import RichTextValue from "mibaby-app/components/site/RichTextValue";

type Props = {
    bind: BindComponentRenderProp;
    field: FbFormModelField;
};

const Radio = ({ field, bind }: Props) => {
    const { onChange, value, validation } = bind;
    const { fieldId, helpText, options } = field;

    return (
        <div className={className("radio", fieldId)}>
            <Title field={field} />
            <div className={`${PREFIX}__radio-group`}>
                {options.map(option => {
                    const id = `radio-${fieldId}-${option.value}`;

                    return (
                        <div className={`${PREFIX}__radio`} key={option.value}>
                            <input
                                checked={value === option.value}
                                onChange={() => onChange(option.value)}
                                name={fieldId}
                                className={`${PREFIX}__radio-input`}
                                type="radio"
                                id={id}
                                value={option.value}
                            />
                            <label htmlFor={id} className={`${PREFIX}__radio-label`}>
                                {option.label}
                            </label>
                        </div>
                    );
                })}
            </div>
            <HelperMessage
                isValid={validation.isValid}
                errorMessage={validation.message}
                helperMessage={<RichTextValue value={helpText} />}
            />
        </div>
    );
};

export default Radio;
