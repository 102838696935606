import React from "react";
import { HeaderProps, HeaderSmall } from "../components/Header";
import Base from "./Base";

export type BareProps = {
    headerProps?: HeaderProps;
    children: React.ReactNode;
};

const Bare = ({ headerProps = {}, children }: BareProps) => {
    return (
        <>
            <HeaderSmall {...headerProps}></HeaderSmall>
            <div className="webiny-pb-section-content">
                <Base>{children}</Base>
            </div>
        </>
    );
};

export default Bare;
