import React from "react";
import { FbFormModelField } from "@webiny/app-form-builder/types";
import RichTextValue from "mibaby-app/components/site/RichTextValue";
import { cx } from "emotion";

export const PREFIX = "webiny-fb-form-field";

export const className = (...suffxies: string[]) =>
    cx(PREFIX, ...suffxies.map(s => `${PREFIX}--${s}`));

export const Title = ({
    field: {
        label,
        settings: { showLabel, fieldText },
    },
}: {
    field: FbFormModelField;
}) => (
    <>
        {showLabel !== false && label && (
            <label className={`${PREFIX}__label webiny-pb-typography webiny-pb-typography-body`}>
                {label}
            </label>
        )}
        {<RichTextValue value={fieldText} />}
    </>
);

/**
 * A component that is used to show helper (description) and validation error messages.
 */
export const HelperMessage = (props: {
    helperMessage?: React.ReactNode;
    errorMessage: React.ReactNode;
    isValid: boolean;
}) => {
    return (
        <div
            className={
                `${PREFIX}__helper-text` +
                (props.isValid === false ? ` ${PREFIX}__helper-text--error` : "")
            }
        >
            {props.isValid === false ? props.errorMessage : props.helperMessage}
        </div>
    );
};
