import * as React from "react";
import { FbFormModelField } from "@webiny/app-form-builder/types";
import { BindComponentRenderProp } from "@webiny/form";
import { Title, HelperMessage, PREFIX, className } from "./common";
import RichTextValue from "mibaby-app/components/site/RichTextValue";

type Props = {
    type?: string;
    bind: BindComponentRenderProp;
    field: FbFormModelField;
};

const Input = ({ type, field, bind }: Props) => {
    const { onChange, value, validation, validate } = bind;
    const { fieldId, helpText, placeholderText } = field;

    // @ts-ignore
    const onBlur = (e: SyntheticInputEvent<HTMLInputElement>) => {
        if (validate) {
            // Since we are accessing event in an async operation, we need to persist it.
            // See https://reactjs.org/docs/events.html#event-pooling.
            e.persist();
            validate();
        }
    };

    return (
        <div className={className("input", fieldId)}>
            <Title field={field} />
            <input
                onBlur={onBlur}
                onChange={e => onChange(e.target.value)}
                value={value || ""}
                placeholder={placeholderText}
                type={type}
                name={fieldId}
                id={fieldId}
                className={`${PREFIX}__input`}
            />
            <HelperMessage
                isValid={validation.isValid}
                errorMessage={validation.message}
                helperMessage={<RichTextValue value={helpText} />}
            />
        </div>
    );
};

export default Input;
