import React from "react";
import MbFooter from "mibaby-frontend/layout/Footer";
import type { LinkComponent } from "mibaby-frontend/layout/Menu";

// TODO: So V2 links work
const DefaultLink: LinkComponent = ({ to, ...props }) => <a href={to} {...props} />;

const Footer = () => <MbFooter className="webiny-pb-section-footer" Link={DefaultLink} />;

export default Footer;
