import layouts from "./layouts";
import type { PbThemePlugin } from "@webiny/app-page-builder/types";

export default [
    {
        type: "pb-theme",
        theme: {
            colors: {
                textPrimary: "var(--webiny-theme-color-text-primary)",
                background: "var(--webiny-theme-color-background)",

                primary: "var(--webiny-theme-color-primary)",
                primaryLight: "var(--webiny-theme-color-primary-light)",
                secondary: "var(--webiny-theme-color-secondary)",
                secondaryLight: "var(--webiny-theme-color-secondary-light)",
                red: "var(--webiny-theme-color-red)",
                redLight: "var(--webiny-theme-color-red-light)",
                cyan: "var(--webiny-theme-color-cyan)",
                cyanLight: "var(--webiny-theme-color-cyan-light)",

                cta: "var(--webiny-theme-color-cta)",
                black: "var(--webiny-theme-color-black)",
                white: "var(--webiny-theme-color-white)",
                grey: "var(--webiny-theme-color-grey)",
                lightgrey: "var(--webiny-theme-color-lightgrey)",

                // TODO: Legacy
                mbGreenB: "var(--webiny-theme-color-primary)",
                mbGreenC: "var(--webiny-theme-color-primary-light)",
                mbGreenD: "var(--webiny-theme-color-primary)",
                mbGreenE: "var(--webiny-theme-color-primary)",
                mbGreyB: "var(--webiny-theme-color-grey)",
                mbGreyC: "var(--webiny-theme-color-grey)",
                mbGreyD: "var(--webiny-theme-color-grey)",
                mbPurpleB: "var(--webiny-theme-color-secondary-light)",
                mbPurpleC: "var(--webiny-theme-color-secondary)",
                mbPurpleD: "var(--webiny-theme-color-secondary)",
            },
            elements: {
                button: {
                    types: [
                        { className: "", label: "Default" },
                        { className: "primary", label: "Primary" },
                        { className: "secondary", label: "Secondary" },
                        { className: "red", label: "Red" },
                        { className: "cyan", label: "Cyan" },
                        { className: "grey", label: "Grey" },
                        { className: "cta", label: "CTA" },
                        { className: "outline-primary", label: "Outline Primary" },
                        { className: "outline-secondary", label: "Outline Secondary" },
                        { className: "outline-red", label: "Outline Red" },
                        { className: "outline-cyan", label: "Outline Cyan" },
                        { className: "outline-grey", label: "Outline Grey" },
                        { className: "outline-cta", label: "Outline CTA" },
                        { className: "simple", label: "Simple" },
                    ],
                },
                heading: {
                    types: [{ className: "webiny-pb-typography-heading", label: "Default" }],
                },
                paragraph: {
                    types: [
                        { className: "webiny-pb-typography-body", label: "Body" },
                        { className: "webiny-pb-typography-description", label: "Description" },
                    ],
                },
                list: {
                    types: [
                        { className: "", label: "Default" },
                        { className: "webiny-pb-typography-list--primary", label: "Primary" },
                        { className: "webiny-pb-typography-list--secondary", label: "Secondary" },
                    ],
                },
                quote: {
                    types: [{ className: "webiny-pb-typography-quote", label: "Default" }],
                },
            },
            fonts: {
                primary: {
                    label: "Primary",
                    value: "var(--webiny-theme-typography-primary-font-family, Lexend Deca Light, sans-serif)",
                },
                secondary: {
                    label: "Secondary",
                    value: "var(--webiny-theme-typography-secondary-font-family, Lexend Deca SemiBold, sans-serif)",
                },
                cursive: {
                    label: "Cursive",
                    value: "var(--webiny-theme-typography-cursive-font-family, Calton Elegance, cursive)",
                },

                // TODO: Legacy
                serif: {
                    label: "Serif",
                    value: "var(--webiny-theme-typography-serif-font-family, Lexend Deca Light, sans-serif)",
                },
                opensans: {
                    label: "Open Sans",
                    value: "var(--webiny-theme-typography-opensans-font-family, Lexend Deca Light, sans-serif)",
                },
            },
            typography: {
                h1: {
                    label: "Heading 1",
                    component: "h1",
                    className: "webiny-pb-typography-h1",
                },
                h2: {
                    label: "Heading 2",
                    component: "h2",
                    className: "webiny-pb-typography-h2",
                },
                h3: {
                    label: "Heading 3",
                    component: "h3",
                    className: "webiny-pb-typography-h3",
                },
                h4: {
                    label: "Heading 4",
                    component: "h4",
                    className: "webiny-pb-typography-h4",
                },
                h5: {
                    label: "Heading 5",
                    component: "h5",
                    className: "webiny-pb-typography-h5",
                },
                h6: {
                    label: "Heading 6",
                    component: "h6",
                    className: "webiny-pb-typography-h6",
                },
                paragraph: {
                    label: "Paragraph",
                    component: "p",
                    className: "webiny-pb-typography-body",
                },
                description: {
                    label: "Description",
                    component: "p",
                    className: "webiny-pb-typography-description",
                },
                blockquote: {
                    label: "Blockquote",
                    component: "blockquote",
                    className: "webiny-pb-typography-blockquote",
                },
                none: {
                    label: "No formatting",
                    component: "div",
                    className: "",
                },
            },
        },
    } as PbThemePlugin,
    ...layouts,
];
