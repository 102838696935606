import React from "react";
import Footer from "../components/Footer";
import Header, { HeaderProps } from "../components/Header";
import Base from "./Base";

export type StaticProps = {
    headerProps?: HeaderProps;
    children: React.ReactNode;
};

const Static = ({ headerProps = {}, children }: StaticProps) => {
    return (
        <>
            <Header {...headerProps} />
            <div className="webiny-pb-section-content">
                <Base>{children}</Base>
            </div>
            <Footer />
        </>
    );
};

export default Static;
