import React from "react";
import type { PbPageLayoutPlugin } from "@webiny/app-page-builder/types";
import type { PbRouteLayoutPlugin } from "mibaby-app-page-builder/types";
import Bare from "./Bare";
import Business from "./Business";
import Offer from "./Offer";
import Static from "./Static";

/**
 * Wrap content pages in content-specific layouts
 */
export default [
    {
        name: "pb-page-layout-bare",
        type: "pb-page-layout",
        layout: {
            name: "bare",
            title: "Bare page",
            component: Bare,
        },
    } as PbPageLayoutPlugin,
    {
        name: "pb-page-layout-static",
        type: "pb-page-layout",
        layout: {
            name: "static",
            title: "Static page",
            component: Static,
        },
    } as PbPageLayoutPlugin,
    {
        name: "pb-page-layout-business",
        type: "pb-page-layout",
        layout: {
            name: "business",
            title: "Business page",
            component: Business,
        },
    } as PbPageLayoutPlugin,
    {
        name: "route-layout-offer",
        type: "route-layout",
        match({ page }) {
            return page?.settings?.content?.["__typename"] === "Offer";
        },
        render({ route, children }) {
            return <Offer pageId={route.page.id}>{children}</Offer>;
        },
    } as PbRouteLayoutPlugin,
];
