import React, { useState, useMemo, useEffect, useRef } from "react";
import { Form, BindComponent, BindComponentRenderProp } from "@webiny/form";
import { className, HelperMessage, PREFIX } from "./fields/common";
import type {
    FbFormModelField,
    FormRenderPropsType,
    FormSubmitResponseType,
} from "@webiny/app-form-builder/types";
import { validation } from "@webiny/validation";
import { ReCaptchaComponent } from "@webiny/app-form-builder/components/Form/components/createReCaptchaComponent";
import { TermsOfServiceProps } from "@webiny/app-form-builder/components/Form/components/createTermsOfServiceComponent";
import type { FormRenderFbFormModelField } from "@webiny/app-form-builder/types";
import { cx } from "emotion";
import RichTextValue from "mibaby-app/components/site/RichTextValue";
import type { ValueJSON } from "slate";
import useAutofill from "mibaby-frontend/util/hooks/useAutofill";

import * as Fields from "./fields";

/**
 * This is the default form layout component, in which we render all the form fields. We also render terms of service
 * and reCAPTCHA (if enabled in form settings), and at the bottom, the submit button. Note that we also utilized
 * the "webiny-form" package, which makes working with forms and form fields a walk in the park.
 *
 * Feel free to use this component as your starting point for your own form layouts. Add or remove things as you like!
 */
const DefaultFormLayout: FormLayoutComponent = ({
    getFields,
    getDefaultValues,
    submit,
    formData: { settings },
    ReCaptcha,
    TermsOfService,
}) => {
    // Is the form in loading (submitting) state?
    const [loading, setLoading] = useState(false);

    // Is the form successfully submitted?
    const [formSuccess, setFormSuccess] = useState(false);

    // All form fields - an array of rows where each row is an array that contain fields.
    const fields = getFields();

    const [formError, setFormError] = useState<Error>();
    const [data, setData] = useFormData(getDefaultValues);

    const steps = useMemo(() => {
        let step = { rows: [] };
        const steps: Step[] = [step];

        for (const row of fields) {
            if (row[0].type === "step") {
                steps[steps.length - 1] = { ...row[0], rows: step.rows };
                steps.push((step = { rows: [] }));
            } else {
                step.rows.push(row);
            }
        }
        return steps;
    }, [fields]);

    const formRef = useRef<HTMLDivElement>();

    const [currentStep, setCurrentStep] = useState(0);
    const hasSteps = steps.length > 1;
    const isLastStep = currentStep === steps.length - 1;
    const step = steps[currentStep];

    useScrollToTop(currentStep, formRef);

    const handleSubmit = async (data: any) => {
        if (isLastStep) {
            setLoading(true);
            setFormError(undefined);
            try {
                const result = await submit(data);
                if (result.error) throw new FormError(result.error);
                setFormSuccess(true);
            } catch (e) {
                setFormError(e);
            } finally {
                setLoading(false);
            }
        } else {
            setData(data);
            setCurrentStep(s => s + 1);
        }
    };

    return (
        <Form onSubmit={handleSubmit} data={data}>
            {({ submit, Bind }) => (
                <div ref={formRef} className={"webiny-fb-form"}>
                    {formSuccess ? (
                        renderMessage(settings.successMessage)
                    ) : (
                        <div className="webiny-fb-form-layout-step">
                            {step.rows.map(renderRow(Bind, formError))}
                            {isLastStep && (
                                <>
                                    {renderTermsOfService(TermsOfService, Bind)}
                                    {renderReCaptcha(ReCaptcha, Bind)}
                                </>
                            )}
                            <div
                                className={`webiny-fb-form-${hasSteps ? "step" : "submit"}-button`}
                            >
                                {/* Back button */}
                                {currentStep > 0 && (
                                    <FormButton
                                        label="Zurück"
                                        variant="default"
                                        onClick={() => setCurrentStep(s => s - 1)}
                                    />
                                )}

                                {/* Forward or submit button */}
                                {isLastStep ? (
                                    <FormButton
                                        label={settings.submitButtonLabel || "Abschicken"}
                                        loading={loading}
                                        onClick={submit}
                                    />
                                ) : (
                                    <FormButton
                                        label={step?.settings?.buttonLabel || "Weiter"}
                                        onClick={submit}
                                    />
                                )}
                            </div>
                            {formError && renderMessage(formError.message, "error")}
                        </div>
                    )}
                </div>
            )}
        </Form>
    );
};

const FormButton = ({
    label,
    variant = "primary",
    loading,
    disabled,
    ...props
}: JSX.IntrinsicElements["button"] & {
    label: string;
    variant?: string;
    loading?: boolean;
}) => (
    <button
        className={cx(
            "webiny-fb-form-page-element-button",
            "webiny-pb-page-element-button",
            `webiny-pb-page-element-button--${variant}`,
            { "webiny-pb-element-button--loading": loading }
        )}
        disabled={loading || disabled}
        {...props}
    >
        {label}
    </button>
);

// eslint-disable-next-line react/display-name
const renderRow = (Bind: BindComponent, formError: Error) =>
    function FieldRow(row: FormRenderFbFormModelField[], index: number) {
        return (
            <div
                key={index}
                className={cx({
                    "webiny-pb-layout-row": true,
                    "webiny-fb-form-layout-row": true,
                    "webiny-fb-form-layout-no-full-width":
                        row.length === 1 && row[0].type === "radio",
                })}
            >
                {row.map(field =>
                    field.type !== "hidden"
                        ? renderFieldCell(field, Bind, formError)
                        : renderHiddenField(field, Bind)
                )}
            </div>
        );
    };

/**
 * Renders a field cell with a field element inside.
 */
const renderFieldCell = (
    field: FormRenderFbFormModelField,
    Bind: BindComponent,
    formError: Error
) => (
    <div key={field._id} className={cx("webiny-pb-layout-column", "webiny-fb-form-layout-column")}>
        <Bind name={field.fieldId} validators={field.validators}>
            {bind => {
                const message = (formError as FormError)?.data?.invalidFields[field.fieldId];
                if (bind.validation.isValid && message) {
                    bind = { ...bind, validation: { isValid: false, message, results: {} } };
                }

                return <>{renderFieldElement({ field, bind })}</>;
            }}
        </Bind>
    </div>
);

/**
 * Renders hidden fields.
 */
const renderHiddenField = (field: FormRenderFbFormModelField, Bind: BindComponent) => (
    <Bind name={field.fieldId} validators={field.validators}>
        {bind => renderFieldElement({ field, bind })}
    </Bind>
);

/**
 * Renders a single form field. You can add additional handling of other field types if needed.
 * All of these components are located in the "./fields" folder.
 */
const renderFieldElement = (props: { field: FbFormModelField; bind: BindComponentRenderProp }) => {
    switch (props.field.type) {
        case "text":
            return <Fields.Input {...props} />;
        case "textarea":
            return <Fields.Textarea {...props} />;
        case "number":
        case "password":
        case "date":
            return <Fields.Input {...props} type={props.field.type} />;
        case "select":
            return <Fields.Select {...props} />;
        case "radio":
            return <Fields.Radio {...props} />;
        case "checkbox":
            return <Fields.Checkbox {...props} />;
        case "checkboxes":
            return <Fields.Checkboxes {...props} />;
        case "file":
            return <Fields.File {...props} />;
        case "hidden":
            return <input type="hidden" value={props.bind.value} />;
        case "layout-text":
            return <RichTextValue value={props.field.settings.text} />;
        default:
            return <span>Cannot render field.</span>;
    }
};

/**
 * Renders Google reCAPTCHA field (checkbox) - to protect us from spam and bots.
 * For this we use the provided ReCaptcha component, which is a render prop component and a regular component
 * at the same time, depending if the function was passed as its children. If no children are present, then
 * it will render the actual Google reCAPTCHA field.
 * Note that you don't have to worry if the reCAPTCHA was actually enabled via the Form Editor - the component
 * does necessary checks internally and will not render anything if it isn't supposed to.
 */
const renderReCaptcha = (ReCaptcha: ReCaptchaComponent, Bind: BindComponent) => {
    return (
        <ReCaptcha>
            {({ errorMessage }) => (
                <div className="webiny-fb-form-recaptcha">
                    <Bind name={"reCaptcha"} validators={validation.create("required")}>
                        {({ onChange, validation }) => (
                            <>
                                <ReCaptcha onChange={onChange} />
                                <HelperMessage
                                    isValid={validation.isValid}
                                    errorMessage={errorMessage}
                                />
                            </>
                        )}
                    </Bind>
                </div>
            )}
        </ReCaptcha>
    );
};

/**
 * Renders the Terms of Service checkbox - which forces the user to agree to our Terms of Service
 * before actually submitting the form.
 * For this we use the provided TermsOfService component, which is a simple render prop component.
 * Note that you don't have to worry if the terms of service option was actually enabled via the Form Editor -
 * the component does necessary checks internally and will not render anything if it isn't supposed to.
 */
const renderTermsOfService = (TermsOfService: TermsOfServiceComponent, Bind: BindComponent) => {
    return (
        <TermsOfService>
            {({ message, errorMessage, onChange }) => (
                <div className="webiny-fb-form-tos">
                    <Bind
                        name={"tosAccepted"}
                        validators={validation.create("required")}
                        afterChange={onChange}
                    >
                        {({ onChange, value, validation, validate }) => {
                            const handleChange = (val: boolean) => {
                                onChange(val);
                                validate && validate();
                            };
                            return (
                                <div className="webiny-pb-layout-column webiny-fb-form-layout-column">
                                    <div className={className("checkbox")}>
                                        <div className={`${PREFIX}__checkbox-group`}>
                                            <div className={`${PREFIX}__checkbox`}>
                                                <input
                                                    className={`${PREFIX}__checkbox-input`}
                                                    type={"checkbox"}
                                                    name="webiny-tos-checkbox"
                                                    id="webiny-tos-checkbox"
                                                    checked={Boolean(value)}
                                                    onChange={() => handleChange(!value)}
                                                />
                                                <label
                                                    htmlFor="webiny-tos-checkbox"
                                                    className={`${PREFIX}__checkbox-label`}
                                                >
                                                    <RichTextValue value={message} />
                                                </label>
                                            </div>
                                        </div>
                                        <HelperMessage
                                            isValid={validation.isValid}
                                            errorMessage={errorMessage}
                                        />
                                    </div>
                                </div>
                            );
                        }}
                    </Bind>
                </div>
            )}
        </TermsOfService>
    );
};

/**
 * Renders the success message.
 */
const renderMessage = (msg?: string, type = "success") => {
    return (
        <div className="webiny-pb-layout-row webiny-fb-form-layout-row">
            <div className="webiny-pb-layout-column webiny-fb-form-layout-column">
                <div className={`webiny-fb-form-form__${type}-message`}>
                    {type === "error" ? (
                        <span dangerouslySetInnerHTML={{ __html: msg }} />
                    ) : (
                        <div
                            className={`${PREFIX}__label webiny-pb-typography webiny-pb-typography-h3`}
                        >
                            <RichTextValue value={msg} default="Vielen Dank!" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

/*
 * Util
 */

/**
 * Merge autofill, default and current form data
 */
const useFormData = (getDefaultValues: () => { [key: string]: any }) => {
    const [stateData, setData] = useState({});
    const autofillData = useAutofill({ user: true, leadUser: true });
    const data = useMemo(() => {
        const fallbackValues = getDefaultValues();
        const defaultValues = Object.fromEntries(
            Object.entries(fallbackValues).filter(entry => entry[1] !== "")
        );

        return { ...fallbackValues, ...(autofillData ?? {}), ...defaultValues, ...stateData };
    }, [autofillData, getDefaultValues, stateData]);
    return [data, setData] as const;
};

/**
 * Scroll to top if advancing the step
 */
const useScrollToTop = (currentStep: number, formRef: React.MutableRefObject<HTMLDivElement>) => {
    const maxStepRef = useRef(currentStep);
    maxStepRef.current = Math.max(maxStepRef.current, currentStep);
    useEffect(() => {
        const form = formRef.current;
        if (!form || !currentStep || currentStep < maxStepRef.current) return;

        const top = form.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top, behavior: "smooth" });
    }, [maxStepRef.current]);
};

/*
 * Types
 */

type Row = FormRenderFbFormModelField[];
type Step = Partial<FormRenderFbFormModelField> & { rows: Row[] };

type TermsOfServiceComponent = React.FC<
    Extend<
        TermsOfServiceProps,
        {
            children: (params: {
                onChange: (value: boolean) => void;
                errorMessage: String;
                message: ValueJSON;
            }) => React.ReactNode;
        }
    >
>;

type FormLayoutComponent = React.FC<
    Extend<FormRenderPropsType, { TermsOfService: TermsOfServiceComponent }>
>;

class FormError extends Error {
    code: string;
    data?: { invalidFields: Record<string, string> };
    constructor(props: FormSubmitResponseType["error"] & { data?: FormError["data"] }) {
        super(props.message);
        this.code = props.code;
        this.data = props.data;
    }
}

export default DefaultFormLayout;
