import React from "react";
import Static, { StaticProps } from "./Static";
import { RouterLink } from "mibaby-app/components/RouterLink";

const Business = (props: StaticProps) => (
    <Static
        {...props}
        headerProps={{
            homepage: "/business",
            menuName: "business-menu",
            Link: RouterLink,
        }}
    />
);

export default Business;
