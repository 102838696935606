import React, { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Redirect, useLocation } from "@webiny/react-router";
import trim from "lodash/trim";
import type { PbPage, PbQueryData, PbRouteContext } from "mibaby-app-page-builder/types";
import { useClientSideTracking } from "mibaby-app/contexts/Cst";
import { Provider as RouteProvider } from "mibaby-frontend/route/Context";
import { GET_PUBLISHED_PAGE, GET_SETTINGS } from "./graphql";
import Render from "./Render";
import withUser from "mibaby-app/util/hoc/withUser";

/**
 * This component will fetch the published page's data and pass it to the `Render` function. Note that if the
 * `preview` query parameter is present, we're getting the page directly by its ID, instead of the URL.
 * The `preview` query parameter is set, for example, when previewing pages from Page Builder's editor / Admin app.
 */
const Page = withUser(user => ({ user }))(({user}) => {
    const { pathname: url, search, hash } = useLocation();
    const suffix = search + hash;

    const path = "/" + trim(url || "", "/");
    const notCanonical = url !== path;

    const query = new URLSearchParams(search);
    const id = query.get("preview");

    // Here we get the page data for current URL, including its content.
    const getPublishedPageQuery = useQuery<PbQueryData<{ getPublishedPage: PbPage }>>(
        GET_PUBLISHED_PAGE(),
        {
            variables: {
                id,
                path,
                returnErrorPage: Boolean(query.get("returnErrorPage")),
                returnNotFoundPage: true,
                preview: !!id,
            },
            skip: notCanonical,
        }
    );

    // Here we get all site data like website name, favicon image, social links etc.
    const getSettingsQuery = useQuery(GET_SETTINGS, { skip: notCanonical });

    const { loading } = getPublishedPageQuery;
    const { loading: userLoading } = user;
    const { data: page, error } = getPublishedPageQuery.data?.pageBuilder?.getPublishedPage || {};
    const settings = getSettingsQuery.data?.pageBuilder?.getSettings?.data || {};

    // Track page view
    useClientSideTracking(!loading && page?.path ? page.path + search : undefined);

    // Mark as a webiny page, so V2 can reload on back navigation
    useEffect(() => {
        if (error?.code !== "v2") history.replaceState({ webiny: true }, "");
    }, [page?.path]);

    // Handle some errors
    if (notCanonical) {
        return <Redirect to={url.substring(0, url.length - 1) + suffix} />;
    } else if (error?.code === "MOVED") {
        return <Redirect to={error.data + suffix} />;
    } else if (error?.code === "V2") {
        return <Reload />;
    }

    // Let's render the page.
    return (
        <RouteProvider value={{ type: "pb", loading, page, error } as PbRouteContext}>
            <Render loading={loading || userLoading} page={page} error={error} settings={settings} />
        </RouteProvider>
    );
});

const Reload = () => {
    useEffect(() => {
        window.location.reload();
    }, []);
    return null;
};

export default Page;
