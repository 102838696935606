import React from "react";
import { Switch, Route } from "@webiny/react-router";
import { PageBuilderProvider } from "@webiny/app-page-builder/contexts/PageBuilder";
import Page from "./components/Page";
import { plugins } from "@webiny/plugins";
import { SiteExpressionProvider } from "mibaby-app/components/expressions";
import { CstProvider } from "mibaby-app/contexts/Cst";
import { UiProvider } from "mibaby-app/contexts/Ui";
// import { I18nProvider } from "mibaby-app/contexts/I18n";

export const App = () => (
            // <I18nProvider>
    <PageBuilderProvider>
        <UiProvider app="site">
                <CstProvider>
                    <SiteExpressionProvider>
                        <Switch>
                            <Route path={"*"} component={Page} />
                        </Switch>

                        {plugins
                            .byType("route")
                            .map(pl => React.cloneElement(pl.route, { key: pl.name, exact: true }))}

                        {plugins.byType("addon-site-render").map(pl => (
                            <React.Fragment key={pl.name}>{pl.render()}</React.Fragment>
                        ))}
                    </SiteExpressionProvider>
                </CstProvider>
        </UiProvider>
    </PageBuilderProvider>
            // </I18nProvider>
);
