import React from "react";
import { FbFormModelField } from "@webiny/app-form-builder/types";
import { BindComponentRenderProp } from "@webiny/form";
import RichTextValue from "mibaby-app/components/site/RichTextValue";
import { Title, HelperMessage, className, PREFIX } from "./common";

type Props = {
    bind: BindComponentRenderProp;
    field: FbFormModelField;
};

const change = ({ option, value, onChange }) => {
    const newValues = Array.isArray(value) ? [...value] : [];
    if (newValues.includes(option.value)) {
        newValues.splice(newValues.indexOf(option.value), 1);
    } else {
        newValues.push(option.value);
    }

    onChange(newValues);
};

const checked = ({ option, value }) => {
    return Array.isArray(value) && value.includes(option.value);
};

const Checkbox = ({ field, bind }: Props) => {
    const { onChange, value, validation } = bind;
    const { fieldId, helpText, options } = field;

    return (
        <div className={className("checkbox", fieldId)}>
            <Title field={field} />
            <div className={`${PREFIX}__checkbox-group`}>
                {options.map(option => (
                    <div className={`${PREFIX}__checkbox`} key={option.value}>
                        <input
                            name={fieldId}
                            className={`${PREFIX}__checkbox-input`}
                            type="checkbox"
                            id={"checkbox-" + fieldId + option.value}
                            checked={checked({ option, value })}
                            onChange={() => change({ option, value, onChange })}
                        />
                        <label
                            htmlFor={"checkbox-" + fieldId + option.value}
                            className={`${PREFIX}__checkbox-label`}
                        >
                            {option.label}
                        </label>
                    </div>
                ))}
            </div>
            <HelperMessage
                isValid={validation.isValid}
                errorMessage={validation.message}
                helperMessage={<RichTextValue value={helpText} />}
            />
        </div>
    );
};

export default Checkbox;
