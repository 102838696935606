import ApolloClient, { ApolloClientOptions } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache } from "@webiny/app/apollo-client/InMemoryCache";
import { ApolloDynamicLink } from "@webiny/app/plugins/ApolloDynamicLink";
import { plugins } from "@webiny/plugins";
import { ApolloCacheObjectIdPlugin } from "@webiny/app/plugins/ApolloCacheObjectIdPlugin";
import type { NormalizedCacheObject } from "apollo-cache-inmemory";
import { createRestLink } from "mibaby-app/apolloRestLink";

/**
 * Passing a location switches to SSR mode
 */
export const createApolloClient = (location?: URL) => {
    const options: Partial<ApolloClientOptions<NormalizedCacheObject>> = {};

    options.cache = new InMemoryCache({
        addTypename: true,
        dataIdFromObject: obj => {
            /**
             * Since every data type coming from API can have a different data structure,
             * we cannot rely on having an `id` field.
             */
            const getters = plugins.byType<ApolloCacheObjectIdPlugin>(
                ApolloCacheObjectIdPlugin.type
            );

            for (let i = 0; i < getters.length; i++) {
                const id = getters[i].getObjectId(obj);
                if (typeof id !== "undefined") return id;
            }

            /**
             * As a fallback, try getting object's `id`.
             */
            return obj.id || null;
        },
    });

    if (location) {
        options.ssrMode = true;
    } else {
        //@ts-ignore
        options.cache.restore("__APOLLO_STATE__" in window ? window.__APOLLO_STATE__ : {});
        // @ts-ignore
        window.getApolloState = () => options.cache.data.data;
    }

    options.link = ApolloLink.from([
        createRestLink(location),
        new ApolloDynamicLink(),
        createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_API_URL }),
    ]);

    return new ApolloClient(options as ApolloClientOptions<NormalizedCacheObject>);
};
