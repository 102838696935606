import Offer from "mibaby-frontend/layout/content/Offer";
import withPage from "mibaby-app/util/hoc/withPage";

const FIELDS = `
  url
  settings {
    content {
      id
      __typename
      ...on Offer {
        title
        price
        comparePrice
        goto
        validUntil,
        asset {
          id
          path
          ext
          mime
          fileVersions {
            w320
            w460
            w640
            w970
            full
          }
        }
        shop {
          id
          name
          logo
        }
      }
    }
  }
`;

export default withPage(FIELDS, ({ page }) => ({
    url: page?.url,
    ...(page?.settings?.content || {}),
}))(Offer);
