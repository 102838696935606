import * as React from "react";
import { FbFormModelField } from "@webiny/app-form-builder/types";
import { BindComponentRenderProp } from "@webiny/form";
import { Title, HelperMessage, className, PREFIX } from "./common";
import RichTextValue from "mibaby-app/components/site/RichTextValue";

type Props = {
    bind: BindComponentRenderProp;
    field: FbFormModelField;
};

const Select = ({ field, bind }: Props) => {
    const { onChange, value, validation } = bind;
    const { fieldId, helpText, placeholderText, options } = field;

    return (
        <div className={className("select", fieldId)}>
            <Title field={field} />
            <select
                value={value || ""}
                onChange={e => onChange(e.target.value)}
                id={fieldId}
                name={fieldId}
                className={`${PREFIX}__select`}
            >
                <option disabled value={""}>
                    {placeholderText}
                </option>
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <HelperMessage
                isValid={validation.isValid}
                errorMessage={validation.message}
                helperMessage={<RichTextValue value={helpText} />}
            />
        </div>
    );
};

export default Select;
