import React, { useEffect, useState } from "react";
import { FbFormModelField } from "@webiny/app-form-builder/types";
import { BindComponentRenderProp } from "@webiny/form";
import { Title, HelperMessage, className } from "./common";
import RichTextValue from "mibaby-app/components/site/RichTextValue";
import { css, cx } from "emotion";
import type { ButtonType } from "mibaby-api-page-builder/types";
import { MIME_TYPES } from "mibaby-common/media/mimeTypes";
import { FileIcon } from "@tntx/file-icon";

const MAX_NUM_FILES = 10;

const TYPE_NAMES = { image: "Bild", document: "Dokument" };

type Props = {
    type?: string;
    bind: BindComponentRenderProp;
    field: FbFormModelField & {
        settings: Partial<{
            fileType: string;
            maxFiles: number;
            buttonType: ButtonType;
            buttonText: string;
        }>;
    };
};

const FileInput = ({ field, bind }: Props) => {
    const { onChange, value, validation, validate } = bind;
    const { fieldId, helpText, settings } = field;
    const { fileType = "any", buttonType = "primary", buttonText } = settings;
    const values: File[] = value || [];

    const maxFiles: number = Math.min(
        field.validation.find(v => v.name === "maxLength")?.settings.value || 1,
        MAX_NUM_FILES
    );
    const multi = maxFiles > 1;

    const mimeTypes = fileType === "any" ? Object.values(MIME_TYPES).flat() : MIME_TYPES[fileType];

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        if (!files.length) return;
        onChange(multi ? [...values, ...files] : [files[0]]);
        e.target.value = null;
        if (validate) validate();
    };
    const handleRemove = (file: File) => {
        onChange(values.filter(f => f !== file));
        if (validate) validate();
    };

    return (
        <div className={className("file", fieldId)}>
            <Title field={field} />
            {values.map((f, i) => (
                <FilePreview key={i} file={f} onRemove={handleRemove} />
            ))}
            <input
                type="file"
                onChange={handleChange}
                name={fieldId}
                id={fieldId}
                multiple={multi}
                accept={mimeTypes.join(", ")}
                style={{ display: "none" }}
            />
            {(!multi || values.length < maxFiles) && (
                <div className="webiny-fb-form-upload-button">
                    <label
                        className={cx(
                            "webiny-fb-form-page-element-button",
                            "webiny-pb-page-element-button",
                            `webiny-pb-page-element-button--${buttonType}`
                        )}
                        htmlFor={fieldId}
                    >
                        {buttonText ||
                            `${TYPE_NAMES[fileType] || "Datei"} ${
                                multi ? "hinzufügen" : values.length ? "ändern" : "auswählen"
                            }`}
                    </label>
                </div>
            )}
            <HelperMessage
                isValid={validation.isValid}
                errorMessage={validation.message}
                helperMessage={<RichTextValue value={helpText} />}
            />
        </div>
    );
};

const FilePreview = ({ file, onRemove }: { file: File; onRemove: (file: File) => void }) => {
    const [url, setUrl] = useState<string>();

    useEffect(() => {
        if (!file.type.startsWith("image/")) return;
        setUrl(URL.createObjectURL(file));
        return () => URL.revokeObjectURL(url);
    }, [file]);

    return (
        <div className={previewStyle}>
            <span className="glyphicon glyphicon-remove" onClick={() => onRemove(file)} />
            {url ? <img src={url} /> : <FileIcon name={file.name} />}
            <p>{file.name}</p>
        </div>
    );
};

/*
 * Styles
 */

const previewStyle = css({
    clear: "right",
    ".glyphicon": {
        float: "right",
        color: "red",
        fontSize: 25,
        cursor: "pointer",
        marginBottom: 5,
    },
    img: {
        display: "block",
        margin: "0 auto 15px",
        maxWidth: "100%",
        maxHeight: 600,
        height: "auto",
    },
    p: { textAlign: "center" },
});

export default FileInput;
