import "cross-fetch/polyfill";
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import { createApolloClient } from "./components/apolloClient";
import { ApolloProvider } from "@apollo/react-components";
import { BrowserRouter } from "@webiny/react-router";
import { ErrorBoundary } from "mibaby-frontend/util/error";
import { App } from "./App";
import "./plugins";
import "./App.scss";

import "mibaby-common/util/hooks/console";

const render = module.hot ? ReactDOM.render : ReactDOM.hydrate;
render(
    <ErrorBoundary>
        <ApolloProvider client={createApolloClient()}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <App />
            </BrowserRouter>
        </ApolloProvider>
    </ErrorBoundary>,
    document.getElementById("root")
);
